import prisma from '@app/lib/prisma';
import { TSiteConfig } from '@services/config/types';

export const SiteStoreId = 1;

export const SiteConfig = [
  {
    store_id: 0,
    name: 'Singapore',
    code: 'sg',
    currencyCode: 'SGD',
    website_id: 1,
    phone: '+65 6299 0110',
    whatsapp: '+65 8825 9691',
    tax: 9,
  },
  {
    store_id: 2,
    name: 'Malaysia',
    code: 'my',
    currencyCode: 'MYR',
    website_id: 2,
    phone: '+60 11-3328 2895',
    whatsapp: '+60 11-3328 2895',
    tax: 0,
  },
  {
    store_id: 3,
    name: 'International',
    code: 'intl',
    currencyCode: 'USD',
    website_id: 3,
    phone: '+65 6299 0110',
    whatsapp: '+65 8825 9691',
    tax: 0,
  },
] as TSiteConfig[];

export const SalesTeam = {
  ONLINE: 'Online',
  MT020: 'Sales Person 1',
  MT021: 'Sales Person 2',
  MT022: 'Sales Person 3',
};

export class CoreConfig {
  static sqlConfig = (location: string) => {

    // select the database based on the location
    let currentDatabase = process.env['MSSQL_DATABASE_SG'];

    if (location.toLowerCase() === 'my')
    currentDatabase = process.env['MSSQL_DATABASE_MY'];

    return {
      user: process.env['MSSQL_USER'],
      password: process.env['MSSQL_PASSWORD'],
      database: currentDatabase ?? process.env['MSSQL_DATABASE'],
      server: process.env['MSSQL_HOST'] || 'localhost',
      pool: {
        max: 10,
        min: 0,
        idleTimeoutMillis: 30000,
      },
      options: {
        encrypt: false, // for azure
        trustServerCertificate: true, // change to true for local dev / self-signed certs
      },
    };
  };

  async getConfig(path: string, storeId: number) {
    return await prisma.core_config_data.findMany({
      where: {
        path: path,
        scope_id: storeId,
      },
    });
  }

  async getVariable(name: string, storeId: number) {
    return await prisma.variable.findUnique({
      include: {
        variable_value: {
          where: {
            store_id: storeId,
          },
        },
      },
      where: {
        code: name,
      },
    });
  }

  async saveVariable(name: string, value: string, storeId: number) {
    const variable = await this.getVariable(name, storeId);

    if (variable) {
      await prisma.$executeRaw`UPDATE variable_value SET plain_value = ${value}, html_value = ${value} WHERE variable_id = ${variable.variable_id} AND store_id = ${storeId}`;
    } else {
      await prisma.variable.create({
        data: {
          code: name,
          name: name,
          variable_value: {
            create: {
              plain_value: value,
              html_value: value,
              store_id: storeId,
            },
          },
        },
      });
    }
  }

}
