import http from '@framework/utils/http';
import { SiteConfig } from './config';
import { GetStoreId } from '@services/mage/cms-helper';
import { GetCurrencyConfig } from '@services/mage/directory-helper';

export class ConfigClientHelper {
  static async getConfig(path: string, storeId: number) {
    //api/config?path=general%2Fstore_information%2Fphone&store_id=0
    const response = await http
      .get(`/config?path=${path}&store_id=${storeId}`)
      .then((res) => res.data);
    return response;
  }
}

export const GetStoreConfig = (storeId: number) => {
  return SiteConfig.find((config) => config.store_id === storeId);
};

export const GetStoreCurrency = (lang: string) => {

	const storeId = GetStoreId(lang);
  	const storeConfig = GetStoreConfig(storeId);
	const currencyCode = storeConfig ? storeConfig.currencyCode : 'SGD';

	const currentCurrency = GetCurrencyConfig(currencyCode);

	return currentCurrency;

}