'use client' // Error boundaries must be Client Components
 
import { ErrorLogger } from '@actions/logger/logger'
import { useEffect } from 'react'
import { GetStoreConfig } from '@services/config/client-helper';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
 
export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {   
    
    const pathname = usePathname();
    const storeConfig = GetStoreConfig(0);

    const storeWhatsapp = storeConfig?.whatsapp
    .replace(/\s+/g, '')
    .replace(/[-+]/g, '');

    useEffect(() => {    

        ErrorLogger(`${error.name} - ${pathname} - ${error.message}`);
        
    }, [error])      
 
  return (

    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-semibold tracking-tight text-gray-900">
          Something went wrong!
          </h1>
          <p className="mt-6 text-pretty text-md font-medium text-red-400">
            {error.message}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href="/"
              className="rounded-md bg-blue-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </Link>
            <Link href={`https://wa.me/${storeWhatsapp}`} className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>

  )
}